<template>
    <div class="content">
        <div class="row">
            <div class="col-4">
                <input type="text" v-model="query" placeholder="Search product" class="form-control"
                    v-on:keyup="triggerSearch()" />
            </div>
            <div class="col"></div>
            <div class="col-auto">
            </div>
        </div>

        <div class="row" v-if="!productsReady">
            <div class="col">
                <div class="mt-4">
                    <div class="loading-placeholder">
                        <div class="spinner-border spinner-border-sm ms-2 big-spinner light-spinner" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="productsReady">
            <div class="col-6">
                <div class="mt-2">
                    <div class="table-container">
                        <table class="table align-middle">
                            <thead class="head">
                                <tr>
                                    <th></th>
                                    <th>Product</th>
                                    <th>Theme</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(product, key) in products.data" :key="key">
                                    <td>
                                        <div class="flex items-center">
                                            <img v-if="product.images[0]"
                                                :src="image(product.images[0].origin_src, 150)" alt loading="lazy"
                                                width="50px" />
                                            <div aria-hidden="true"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex flex-column">
                                            {{ product.name }}
                                            <span class="pale">{{ product.sku }}</span>
                                        </div>
                                    </td>
                                    <td class="text-end pe-5">
                                        <div v-for="(theme, k) in themes" :key="k" class="form-check">
                                            <input type="radio" :id="`theme-${product.id}-${k}`"
                                                :name="`theme-${product.id}`" :value="theme.value"
                                                @change="themeChanged(product, $event)" class="form-check-input"
                                                :checked="theme.value == product.theme">
                                            <label :for="`theme-${product.id}-${k}`" class="form-check-label"
                                                :class="[{ 'pale': theme.value != product.theme, 'selected': theme.value == product.theme }, theme.class]">
                                                {{ theme.label }}
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <pagination class="paginator" :limit="4" align="center" :data="products"
                    @pagination-change-page="getProducts"></pagination>
            </div>
            <div class="col-6">
                <div class="d-flex h-100 align-items-start mt-2">
                    <div class="row">
                        <div class="col-12 mb-3">
                            <button class="vave-btn ms-1 btn-green" :disabled="submitButtonEnabled || loading"
                                @click="submitProducts">Submit</button>
                        </div>
                        <div class="col-12" v-if="Object.keys(friendlyThemesChanged).length">
                            <div class="table-container">
                                <table class="table align-middle">
                                    <thead class="head">
                                        <tr>
                                            <th>Product</th>
                                            <th>Theme</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(product, theme) in friendlyThemesChanged" :key="product + theme">
                                            <td>{{ theme }}</td>
                                            <td>{{ product }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import axios from "./../http.js";
import pagination from "laravel-vue-pagination";
import imageProxy from "./../imageProxy";
import { formatValue } from "./../formatters";

export default {
    data() {
        return {
            products: {},
            loading: false,
            query: "",
            per_page: 10,
            productsReady: false,
            themesChanged: {},
            friendlyThemesChanged: {},
            currentPage: 1,
            themes: [
                { label: 'Default', value: 0, class: 'default' },
                { label: 'Clearance', value: 1, class: 'clearance' },
                // {label: 'Other', value:2 , class:'other'},
            ],
        };
    },
    mounted() {
        this.getProducts();
    },
    computed: {
        submitButtonEnabled() {
            return Object.keys(this.themesChanged).length == 0;
        }
    },
    components: {
        pagination,
    },
    methods: {
        findThemeById(id) {
            return this.themes.find((theme) => theme.value == id);
        },
        themeChanged(product, event) {
            this.$set(this.themesChanged, product.id, event.target.value);
            this.$set(this.friendlyThemesChanged, product.name, this.findThemeById(event.target.value).label);
        },
        submitProducts() {
            if (this.loading) return;
            this.loading = true;
            axios.post('/api/admin/themes', { products: this.themesChanged }).then((r) => {
                if (r.status == 200) {
                    this.$toast.success("Products updated successfully");
                    this.getProducts(this.currentPage);
                    this.themesChanged = {};
                    this.friendlyThemesChanged = {};
                    this.query = "";
                }
            }, (e) => {
                console.log(e);
                this.$toast.error("Error submitting products");
            }).finally(() => {
                this.loading = false;
            });
        },
        formatValue,
        image: imageProxy.image,
        getProducts(page = 1) {
            this.currentPage = page;
            this.products = {};
            axios
                .get("/api/admin/themes/products?per_page=" + this.per_page + "&page=" + page)
                .then(
                    (r) => {
                        this.productsReady = true;
                        if (r.status == 200) {
                            this.products = r.data;
                        }
                    },
                    (e) => {
                        this.productsReady = true;
                        console.log(e);
                    }
                );
        },
        triggerSearch: _.debounce(function () {
            this.output = "";
            this.products = [];
            this.productsReady = false;
            axios
                .get("/api/admin/themes/products/search?per_page=99&page=1&q=" + this.query)
                .then((r) => {
                    this.productsReady = true;
                    if (r.status == 200) {
                        this.products = r.data;
                    }
                });
        }, 500),
    },
};
</script>


<style lang="scss" scoped>
.selected {
    font-weight: bold;

    &.clearance {
        background-color: #f8d7da;
        color: #721c24;
        border-color: #f5c6cb;
    }

    &.default {
        background-color: #d1ecf1;
        color: #0c5460;
        border-color: #bee5eb;
    }
}
</style>